import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '../slader.scss';
import {
  Autoplay
} from 'swiper/modules';

import img1 from '../assets/images/windowsServer2022.png'
import img2 from '../assets/images/windowsServer2025.jpeg'
import img3 from '../assets/images/windows8.jpg'
import img4 from '../assets/images/windows7.jpg'

const Slider1 = () => {
  const [data] = useState([
    {
      name: 'Windows Server 2022 2890',
      images: img1
    },
    {
      name: 'Windows Server 2025 3890',
      images: img2
    },
    {
      name: 'Windows 8 1390',
      images: img3
    },
    {
      name: 'Windows 7 1390',
      images: img4
    },
  ])
  return (
    <>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        modules={[Autoplay]}
        loop={true}
        autoplay={
          {
            delay: 2500,
            disableOnInteraction: false,
          }
        }
        className="mySwiper"
      >
        {
          data.map((item) => {
            return (
              <SwiperSlide>
                <div className='swager_item_bg' >
                  <img src={item.images} />
                </div>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </>
  );
}


export default Slider1