import React, { useState } from 'react';
import './Main.scss'
import Slider1 from '../Slider1'
import TopProducts from '../TopProducts'
import Card from '../../card/Card';
import DynamicTitle from '../../dynamicTitle/DynamicTitle';
import Footer from '../Fotter'
import  Modal from '../Modal'

const Main = () => {
  const imgData = [
    {
      id:1,
      title: "Windows Server 2022",
      img: "https://csitltd.ru/upload/iblock/240/WINDOWS-SERVER-2022.png",
      desc: "Ключ активации для Microsoft Windows Server 2022 Standard высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX- XXXXX - XXXXX - XXXXX - XXXXХ",
      price: '2790 ₽'
    },
    {
      id: 2,
      title: "Windows 7",
      img: "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202301/windows-7-logo_2-sixteen_nine.jpg",
      desc: "Ключ активации для Windows 7 Ultimate (Максимальная) высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1390 ₽'
    
    },
    {
      id: 3,
      title: "Windows 8",
      img: "https://d1jnzwil5g8le2.cloudfront.net/content/images/thumbs/0001573_windows-8-fundamentals_800.jpeg",
      desc: "Ключ активации для Windows 8.1 Enterprise (Корпоративная) высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1390 ₽'
    
    },
    {
      id: 4,
      title: "Windows Server 2025",
      img: "https://licendi.com/media/wysiwyg/Windows_Server_2025.png",
      desc: "Ключ активации для Microsoft Windows Server 2025 Standard высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'3890 ₽'
    
    },
    {
      id: 5,
      title: "Windows 10",
      img: "https://habrastorage.org/getpro/habr/post_images/168/d54/94d/168d5494da108346fdb5dd6b660e0335.jpg",
      desc: "Ключ активации для Windows 10 Pro (Профессиональная) высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1590 ₽'
    
    },
    {
      id: 6,
      title: "Windows 11",
      img: "https://brothers.az/public/news/1632727378windows%2011.jpg",
      desc: "Ключ активации для Windows 11 Pro (Профессиональная) высылается покупателю сразу после оплаты. Он позволяет активировать ОС и использовать лицензионное программное обеспечение без каких-либо ограничений, в том числе скачивать и устанавливать обновления, регулярно выпускаемые компанией-производителем софта.По указанным клиентом контактным данным высылается электронный код активации оригинальной ОС в формате XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1990 ₽'
    
    },
    {
      id: 7,
      title: "Office 365",
      img: "https://www.curveballsolutions.com/wp-content/uploads/2020/02/office365.png",
      desc: "После оплаты вы мгновенно получаете новый аккаунт с постоянной подпиской для использования продукта Microsoft Office 365 Professional Plus. Полученные данные вводятся на сайте portal.office.com, или непосредственно в сам пакет Office 365. После входа необходимо будет изменить пароль. Новый, установленный вами пароль не подлежит изменению или восстановлению. Нельзя изменить имя аккаунта. Аккаунт можно использовать на устройствах под управлением следующих платформ: Windows 10/11, macOS, Android, iOSАккаунт возможно использовать до 5 устройств одновременно",
      price:'1990 ₽'
    
    },
    {
      id: 8,
      title: "Office 2021",
      img: "https://volmax.kz/upload/iblock/18d/b3pvmwbiypnk8g1nv6lj908y7e98yw73/office-2021-_-bessrochnyy-paket-prilozheniy.jpg",
      desc:"•	После оплаты вы мгновенно получаете индивидуальный ключ активации Microsoft Office 2021 Professional Plus. После ввода ключа вы сможете воспользоваться всеми преимуществами использования лицензионного ПО без каких либо ограничений, а также скачивать пакеты обновлений, выпускаемые корпорацией Microsoft. Покупатель получает электронный оригинальный код активации формат:XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1990 ₽'
    
    },
    {
      id: 9,
      title: "Office 2021 MacOS",
      img: "https://brytesoft.com/media/catalog/product/m/i/microsoft-office-2021-home-and-business-mac-cover-brytesoft.png",
      desc:"После оплаты вы мгновенно получаете новый аккаунт с постоянной подпиской для использования продукта Microsoft Office 365 Professional Plus. Полученные данные вводятся на сайте portal.office.com, или непосредственно в сам пакет Office 365. После входа необходимо будет изменить пароль. Новый, установленный вами пароль не подлежит изменению или восстановлению. Нельзя изменить имя аккаунта. Аккаунт можно использовать на устройствах под управлением следующих платформ: Windows 10/11, macOS, Android, iOS. Аккаунт возможно использовать до 5 устройств одновременно",
      price:'1990 ₽'
    
    },
    {
      id: 10,
      title: "Office 2019",
      img: "https://i.pcmag.com/imagery/reviews/03bB3uVy777JAcn4AxkGMev-13.fit_lim.size_1050x591.v1569473754.jpg",
      desc:"После оплаты вы мгновенно получаете индивидуальный ключ активации Microsoft Office 2019 Home And Student. После ввода ключа вы сможете воспользоваться всеми преимуществами использования лицензионного ПО без каких либо ограничений, а также скачивать пакеты обновлений, выпускаемые корпорацией Microsoft.Покупатель получает электронный оригинальный код активации формата:XXXXX-XXXXX-XXXXX-XXXXX-XXXXХ",
      price:'1790 ₽'
    
    },
    {
      id: 11,
      title: "Office 2019 MacOS",
      img: "https://brytesoft.com/media/catalog/product/cache/bb9874c4f5fb8df1e4307d8e39ded1e4/m/i/microsoft-office-2019-home-and-business-mac-cover-brytesoft.png",
      desc: "После оплаты вы мгновенно получаете новый аккаунт с постоянной подпиской для использования продукта Microsoft Office 365 Professional Plus. Полученные данные вводятся на сайте portal.office.com, или непосредственно в сам пакет Office 365. После входа необходимо будет изменить пароль. Новый, установленный вами пароль не подлежит изменению или восстановлению. Нельзя изменить имя аккаунта. Аккаунт можно использовать на устройствах под управлением следующих платформ: Windows 10/11, macOS, Android, iOS.Аккаунт возможно использовать до 5 устройств одновременн",
      price:'1990 ₽'
  
    },
  ]

  const [selectedItem, setSelectedItem] = useState()
  const [open,setOpen] = useState()

  const onSelectItem = (id)=>{
    setSelectedItem(imgData.filter((elm)=>elm.id !== id))
    setOpen(true)
  }
  const onCloase = (id) => {
    setSelectedItem(imgData.filter((elm)=>elm.id !== id))
    setOpen(false)
  }
  return (
    <div className='main'>
      <header>
        <nav>
          <ul>
            <li>По будням с 11:00 до 18:00 </li>
            <li>По выходным с 11:00 до 16:00</li>
          </ul>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone"><path d="M27.308,20.649l-2.2-2.2a3.521,3.521,0,0,0-4.938-.021,2.152,2.152,0,0,1-2.729.267A15.026,15.026,0,0,1,13.3,14.562a2.181,2.181,0,0,1,.284-2.739A3.521,3.521,0,0,0,13.553,6.9l-2.2-2.2a3.514,3.514,0,0,0-4.961,0l-.633.634c-3.3,3.3-3.053,10.238,3.813,17.1,4.14,4.141,8.307,5.875,11.686,5.875a7.5,7.5,0,0,0,5.418-2.061l.634-.634A3.513,3.513,0,0,0,27.308,20.649ZM25.894,24.2l-.634.634c-2.6,2.6-8.339,2.125-14.276-3.813S4.571,9.34,7.171,6.74L7.8,6.107a1.511,1.511,0,0,1,2.133,0l2.2,2.2a1.511,1.511,0,0,1,.021,2.11,4.181,4.181,0,0,0-.531,5.239,17.01,17.01,0,0,0,4.713,4.706,4.179,4.179,0,0,0,5.231-.517,1.512,1.512,0,0,1,2.118.013l2.2,2.2A1.51,1.51,0,0,1,25.894,24.2Z"></path></svg>
            <span>+7 (996) 224-88-65</span>
          </div>
        </nav>
      </header>
      <Slider1 />
      <DynamicTitle title={"Категории товаров"} subtitle={'Выберите нужную категорию'} />
      <div className='window_cards'>
        {
          imgData.map(item => {
            return (
              <Card
              id={item.id}
                image={item.img}
                title={item.title}
                description={item.desc}
                price={item.price}
                onSelectItem={onSelectItem}
              />
            )
          })
        }

      </div>
      <DynamicTitle title={"Товары дня"} subtitle={'Ограниченное время'} />
      <TopProducts />
      <Footer />
       {
         open && <Modal onCloase={onCloase} / >
       }
    </div>
  );
}

export default Main;
