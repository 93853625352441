import React from 'react'
import '../modal.scss'

const Modal = ({onCloase}) => {
  return (
    <div className="modal">
        <div className='content'>
          <div className='cloase'><span onClick={onCloase}>X</span></div>
          <div className="content_main">
            <p>Мы используем ваш email только для отправки информации о покупке. Ваши данные будут в безопасности и не будут переданы третьим лицам.</p>
            <input type='mail' placeholder='E-mail' />
            <div className='button' onClick={onCloase}>Отправить</div>
          </div>
        </div>
    </div>
  )
}

export default Modal