import React , {useState} from 'react';
import './Card.scss';

const Card = ({
    id,
    image,
    title,
    description,
    price,
    onSelectItem
  }) => {
  const onOpenModal = () => {
    onSelectItem(id)
  }

  return (
    <div className="card">
      <img src={image} alt={title} className="card__image" />
      <div className="card__content">
        <h2 className="card__title">{title}</h2>
        <h2 className="card__title">{price}</h2>
        <p className="card__description">{description}</p>
      </div>
      <div className="buy" onClick={onOpenModal}>
          Купить
      </div>
     
    </div>
  );
};

export default Card;