import React from 'react'
import '../TopProducts.scss'
import Timer from './Timer'
import img1 from '../assets/images/1.png'
import img2 from '../assets/images/2.png'
const TopProducts = () => {
  return (
    <div className='row'>
        <div className="item">
            <div className="item_img" >
                <img src={img1} alt="img1" />
            </div>
            <div className="item_info">
                <h2>Office 2021 Professional Plus</h2>
                <div className="star_section" >
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="text">851 отзыва</span>
                </div>
                <div className="price">
                    <h2>1990 ₽</h2>
                    <del>2190 ₽</del>
                    <div className="skit">-9%</div>
                </div>
                <div className = "timer" >
                    <Timer />
                </div>
            </div>
        </div>
        <div className="item">
            <div className="item_img" >
                <img src={img2} alt="img1" />
            </div>
            <div className="item_info">
                <h2>Windows 11 Pro (Профессиональная)</h2>
                <div className="star_section" >
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="text">351 отзыва</span>
                </div>
                <div className="price">
                    <h2>1990 ₽</h2>
                    <del>2290 ₽</del>
                    <div className="skit">-13%</div>
                </div>
                <div className = "timer" >
                    <Timer />
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopProducts